import React from "react";
import './BottomNavigation.css'

function BottomNavigation() {
    return (
        <>

            <div >
                <footer class="footer_color" >

                    <p>宇爵工程顧問股份有限公司 │ 臺中市北屯區和福路191號15樓之1
                    </p>
                    <p>信箱: yujyueh@gmail.com │ 統編: 93778504</p>
                    <p>© Copyright © 2023.宇爵工程顧問有限公司</p>

                </footer>
           


            </div>

        </>
    )
}


export default BottomNavigation