import * as React from 'react';

import './TopicButton.css'
export default function TopicButton(props) {
    return (
       <>
        
        <button className='TopicButton' >{props.name}</button>


       </>
    
    );
  
}
